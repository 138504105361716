@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&family=Open+Sans:wght@600&display=swap');

:root {
  --bg-color: #2E3440;
  --bg-color-text: #3B4252;
}

body {
  margin: 0 auto;
  padding: 0 .8rem;
  background-color: var(--bg-color);
  font-family: 'Nunito', sans-serif;
  box-sizing: border-box;
}